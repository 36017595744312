// importing
import { useState,useEffect } from "react"
import styles from "./index.module.scss"
import upArrow from "../../resources/assets/icons/angle-up-solid.svg"
import downArrow from "../../resources/assets/icons/angle-down-solid.svg"
import MenuBody from "./MenuBody"
import { API_getModuleNames } from "../../store/actions/facilitatorActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"


const Menu = ({setActiveButton, activeButton, setIsOpen}) => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.facilitatorReducer)

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setActiveButton(false)

    })
  },[])

  return (
    <>
      <div className={styles.menu}>
        <button className={`${styles.menu__btn} shadow`} onClick={(e) => {
            e.stopPropagation();
          setActiveButton(!activeButton);
          setIsOpen(false)
          }
        }>
          {t("Explore")}
          {
            !activeButton &&
            <img className={styles.menu__btn__img} src={downArrow} alt="" />
          }
          {
            activeButton &&
            <img className={styles.menu__btn__img} src={upArrow} alt="" />
          }
        </button>
        {
          activeButton &&
          <MenuBody data={data} setActiveButton={setActiveButton}/>
        }
      </div>
    </>
  )
}

export default Menu