import React, { useRef, useState } from "react";
import classes from "./index.module.scss";
import images from "../../resources/images";
import { Col, Ratio, Row } from "react-bootstrap";
import Button from "../Buttons";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import CustModal from "../Modal";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';

const ResourceCard = ({ item, size, isReading, center, wrapper, module, week, hideBtn }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const { t } = useTranslation();
	const [modalShow, setModalShow] = useState(false);


	const downloadImage = () => {
		saveAs(lang == "en" ? item.media : item.media_arabic, `file.${item.type == "PDF" ? "pdf" : "png"}`);
	};
	const resources = {
		resource_name: item.resource_name,
		resource_name_arabic: item.resource_name == "Facilitator Guide" ? "دليل الميسرة" : "دليل الأطفال للقراءة",
	};

	return (
		<React.Fragment>
			<Col
				md={size ? size : 6}
				xl={size ? size : 4}
				className={`${size && !isReading ? "px-0" : ""} ${center ? "mx-auto" : ""} ${size && !isReading ? "my-5" : ""}`}
			>
				<button className={classes.card}>
					<div className={classes.relative}>
						{item?.type === "PDF" && (
							<Row className={`${classes.card_top} w-100 mx-0 justify-content-between`}>
								{item.thumbnail && (
									<div className={classes.card_media}>
										<img className="img-fluid" src={lang == "en" ? item.thumbnail : item.thumbnail_arabic} />
									</div>
								)}
								<div className={`d-flex flex-column align-items-start ${item.thumbnail ? "" : "w-100"}  ${classes.card_text}`}>
									{isReading ? (
										<h1 className="semi-bold f-23">{lang == "en" ? resources.resource_name : resources.resource_name_arabic}</h1>
									) : (
										<h1 className="semi-bold f-23 " style={{ textAlign: lang == "en" ? "left" : "right" }}>
											{lang == "en" ? item.title : item?.title_arabic}
										</h1>
									)}
									<p className="meduim f-16 " style={{ textAlign: lang == "en" ? "left" : "right" }}>
										{module}: {week}
									</p>
								</div>
							</Row>
						)}
					</div>

					<div className={`${item.type === "PDF" ? classes.resource_content : "d-flex flex-column align-items-start"}`}>
						{wrapper&&<span>{wrapper}</span>}
						{item.type === "PDF" ? (
							<React.Fragment>
								{!hideBtn &&
								<a
									text="View Pdf"
									className={`${classes.download}`}
									onClick={()=>setModalShow(true)}
								>
									{t("View Pdf")}
								</a>
}

								<a  href={lang == "en" ? item.media : item.media_arabic}>
									<images.download />
								</a>

								<CustModal newTabLink={false} show={modalShow} onHide={() => setModalShow(false)} noModalHeader={true}>
									<Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
												<Viewer fileUrl={lang == "en" ? item.media : item.media_arabic} />
									</Worker>
								</CustModal>
							</React.Fragment>
						) : (
							<p className="f-18 meduim text-dark">{lang == "en" ? item.title : item?.title_arabic}</p>
						)}
					</div>
				</button>
			</Col>

		</React.Fragment>
	);
};

export default ResourceCard;
