
import React, { useRef } from "react";
const ProgramLevelTab = (props) => {
  const currentTabRef = useRef();
  const getSiblings = (element) =>
    Array.from(element.parentNode.children).filter(function (sibling) {
      return sibling !== element;
    });
  const activate = () => {
    const contentElement = props.contentRef.current;
    contentElement.classList.add("show");
    contentElement.classList.add("active");
    const siblingsOfContentElement = getSiblings(contentElement);
    siblingsOfContentElement.forEach((removedContent) => {
      removedContent.classList.remove("show");
      removedContent.classList.remove("active");
    });

    currentTabRef.current.classList.add("active");
    const siblingsOBtnElement = getSiblings(currentTabRef.current);
    siblingsOBtnElement.forEach((removedContent) => {
      removedContent.classList.remove("active");
    });
  };
  return (
    <button
      ref={currentTabRef}
      onClick={activate}
      className={`nav-link ${props.initActivation ? "active" : ""}`}
      id="v-pills-home-tab"
      type="button"
      role="tab"
    >
      <div className="nav-btn-img-container">
        <img className="img-fluid" src={props.img} alt="national Childhood Logo" />
      </div>
      <div className={'tab_text'}>
        <p className={`f-14`}>{props.text}</p>
        <h2 className={`f-18 bold`}>{props.title}</h2>
      </div>
    </button>
  );
};
export default ProgramLevelTab;
