import React, { Fragment, useEffect, useState } from "react";
import CustomHeroSection from "../../components/CustomHeroSection";
import images from "../../resources/images";
import ParentAccordion from "./components/ParentAccordion";
import classes from "./index.module.scss";
import { endpoints, baseUrl } from "../../network/url";
import { API_getParentWeeks } from "../../store/actions/parentActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useParams, useLocation} from "react-router-dom";
import WeekHeroSection from "../../components/WeekHeroSection";
import FeaturedComponent from "../../components/FeaturedComponent";
import AuthLayout from "../../layouts/AuthLayout";

const ParentWeek = () => {
	const params=useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState("");
	const [video, setVideo] = useState();
	const[media, setMedia]=useState({})
	const location = useLocation();


	const lang = useSelector((state) => state.settingsReducer.lang);


	useEffect(async() => {
		setLoading(true);
		const res = await dispatch(API_getParentWeeks(params.module, params.week));
		setData(res.data);
		setMedia({media:res.data.theme_media, media_arabic:res.data.theme_media_arabic})
		setLoading(false);

	}, [location]);


	return (
		<AuthLayout>
			{loading ? (
				<div className={classes.loading}>
					<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
				</div>
			) : (
				<React.Fragment>
					<WeekHeroSection isParent={true} media={media}  weekSlug={data.slug} title={lang=="en"?data.title:data.title_arabic}/>

					<section className="m-block">
						<h4 className="mb-5 f-32 bold text-center">{t("Module Content")}</h4>


						<ParentAccordion days={data?.days} weeklyResources={data?.weekly_resources}  week={lang=="en"?data?.title:data?.title_arabic} module={lang=="en"?data?.module.title:data?.module.title_arabic} />
					</section>

				</React.Fragment>
			)}
		</AuthLayout>
	);
};
export default ParentWeek;
