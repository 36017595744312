import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_getCaregiverData, API_postCaregiverInputVals } from "../../../store/actions/caregiverActions";
import classes from "./FormItem.module.scss";

const FormItem = (props) => {
	const dispatch = useDispatch();
	const [inputVal, setInputVal] = useState(props.item.selected);
	const lang = useSelector((state) => state.settingsReducer.lang);
	const toggleInput = async (e) => {
		setInputVal(e.target.checked);
		await dispatch(API_postCaregiverInputVals(props.item.id, e.target.checked));
		await dispatch(API_getCaregiverData());
	};
	useEffect(() => {
		setInputVal(props.item.selected);
	}, [props.item]);
	return (
		<div key={props.item.title} className={classes["input-item"]}>
			<input onChange={toggleInput} checked={inputVal} type="checkbox" id={props.item.title} name={props.item.title} />
			<label className={`f-16`} htmlFor={props.item.title}>
				{lang == "en" ? props.item.title : props.item.title_arabic}
			</label>
		</div>
	);
};

export default FormItem;
