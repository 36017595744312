import { constants } from "../constants";

const initialState = { show: false };
export default function signInReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SIGN_IN_MODAL:
      return { show: action.payload };
    default:
      return state;
  }
}