import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { removeUnauthedToken } from "../../helpers/removeUnauthedTokens";
export const API_getChannelData = (type, page) => {
    return async () => {
      try {
        const response = await axios.post(`${baseUrl}/channel/media?page=${page}`,{
          'channel_type':type
          
        },{
          headers: localStorage.getItem("token")
            ? {
                Authorization: "Token " + localStorage.getItem("token"),
              }
            : null,
        });
        return response;
      } catch (error) {
        if(error?.response?.status === 401){
          removeUnauthedToken()
        }
        return error.response;
      }
    };
  };