import React, { useEffect, useState } from "react";
import images from "../../../resources/images";
import { useTranslation } from "react-i18next";
import classes from "./SectionLanding.module.scss";
import { useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FilterDropdown from "../components/FilterDropdown";
const SectionLanding = () => {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);


	return (
		<section className={`${classes.landing}`}>

			<div className={`${classes.left} p-inline`}>
				<h1 className={`f-42 bold`}>{t("Egyptian STEM for Early Years Program")}</h1>
				<p className="f-18 light">{t("Welcome to STEMEY - PLJ  platform! Embark on the Children's journey as they start exploring the world around them")}</p>

				<FilterDropdown/>
			</div>

			<div className={`${classes.right}`}>
				<LazyLoadImage src={lang=="en"?images.nurseryChildren:images.nurseryChildrenAr} effect="blur" alt="nursery children"/>
			</div>


		</section>
	);
};
export default SectionLanding;
