import React, { useState } from "react";
import classes from "./index.module.scss";
import images from "../../resources/images";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustModal from "../Modal";
import ResourceCard from "../ResourceCard";
import { useTranslation } from "react-i18next";
import { setActivityVideo } from "../../store/actions/activityVideoAction";


const ActivityCard = ({ activity, headFontColor, bgColor, hasList, isFacilitator, segment, week, module,grid }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState("");
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		if(activity.resources.length >=1){
			console.log(activity);
			setModalShow(true)
		}
	};
	const [modalShow, setModalShow] = useState(false);
	return (
		<React.Fragment>
			<button
				className={`${classes.card} ${!hasList ? classes.card_custom : ""} ${activity.resources.length < 1? classes.cursorShape:""}`}
				style={{ backgroundColor: bgColor }}
				onClick={isFacilitator && handleShow}
			>
				{activity?.activity_name === "Talk Together" && <div className={`${classes.border_1} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Move Together" && <div className={`${classes.border_2} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Pretend Together" && <div className={`${classes.border_3} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Explore Together" && <div className={`${classes.border_4} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Be Creative Together" && <div className={`${classes.border_5} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Make Music Together" && <div className={`${classes.border_6} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Read Together" && <div className={`${classes.border_7} ${classes.absolute}`}></div>}
				{activity?.activity_name === "Solve Together" && <div className={`${classes.border_8} ${classes.absolute}`}></div>}
				{segment == true && <div className={classes.videoIcon} onClick={ (e)=> {
					e.stopPropagation();
					const timeLocalized= lang== "en" ? activity.start:activity.start_ar
					dispatch(setActivityVideo(timeLocalized));
					window.scrollTo(0,0)
					}}>
				<images.activityVideoIcon />
				</div>}

				<Row className={`mx-0 ${classes.card_wrapper} ${grid ? classes.grid:""}`}>
					<Col className={`${classes.grayBg} ${hasList ? classes.grayBg_custom : ""}`} md={hasList ? 4 : 12}>
						<div className={`${classes.circle}`}>
							{activity?.activity_name === "Talk Together" && <images.f_talk />}
							{activity?.activity_name === "Move Together" && <images.f_move />}
							{activity?.activity_name === "Pretend Together" && <images.f_pretend />}
							{activity?.activity_name === "Explore Together" && <images.f_explore />}
							{activity?.activity_name === "Be Creative Together" && <images.f_create />}
							{activity?.activity_name === "Make Music Together" && <images.f_sing />}
							{activity?.activity_name === "Read Together" && <images.f_read />}
							{activity?.activity_name === "Solve Together" && <images.f_solve />}

						</div>

						<div className={`${classes.card_text} `} style={{ textAlign: lang == "en" ? "left" : "right" }}>
							<h2 className="f-20 bold mb-2" style={{ color: headFontColor }}>
								{lang == "en" ? activity.activity_name : activity.activity_name_arabic}
							</h2>
							<p className="f-18">{lang == "en" ? activity.topic : activity.topic_arabic}</p>
						</div>
					</Col>

					{/* {hasList} */}
					{hasList && (
						<Col md={8}>
							<ul className={classes.listItems}>
								{activity.resources.map((resource, index) => (
									<li key={index} style={{ textAlign: lang == "en" ? "left" : "right" }}>
										{lang == "en" ? resource.item : resource.item_arabic}
									</li>
								))}
							</ul>
						</Col>
					)}
				</Row>
			</button>

			<CustModal
				item={activity}
				show={modalShow}
				onHide={() => setModalShow(false)}
				isReading={activity?.activity_name == "Read Together" ? true : false}
			>
				{activity.resources.length > 1 && (
					<React.Fragment>
						<div className={classes.absoluteBg}>
							<p className={`f-18 light px-3 text-white`}>
								{t('In "Read Together" Activities, We recommend you to use both the Children guide to read aloud for Children, while follow the activity instructions and tips from the Facilitator guide')}{" "}
							</p>
						</div>
						<div className={classes.pad}>
							{/* <h3 className={`f-18 meduim my-4`}>{t("Practice Resources")}</h3> */}
							<div className={`${classes.cards_wrapper}`}>
								{activity &&
									activity.resources.map((item, index) => {
										return item.media && lang== "en" ?
										<ResourceCard item={item} key={index} size={6} isReading={true}  module={module} week={week} hideBtn={true}/>
										:item.media_arabic && lang == "ar" ? (
										<ResourceCard item={item} key={index} size={6} isReading={true}  module={module} week={week} hideBtn={true}/>
										):null})}
							</div>
						</div>
					</React.Fragment>
				)}
				{activity.resources.length == 1 &&
					activity.resources.map((resource, index) => (
						<img style={{ width: "100%" }} src={lang === "ar" ? resource.media_arabic : resource.media} key={index} />
					))}
			</CustModal>
		</React.Fragment>
	);
};
export default ActivityCard;
