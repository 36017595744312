import React from "react";
import Button from "../../../components/Buttons";
import ChannelCard from "../../../components/ChannelCard";
import classes from "./SectionFooter.module.scss";
import { t } from "i18next";
import images from "../../../resources/images";
import { useSelector } from "react-redux";

const SectionFooter = () => {
	const lang = useSelector((state) => state.settingsReducer.lang);

	const items = [
		{
			type: "Channel",
			thumbnail: images[`fg_${lang}`],
			title: "Facilitator Weekly Together Practices",
			title_arabic: "الأنشطة المشتركة  الأسبوعية ",
			route:'weekly_together'
		},
		{
			type: "Channel",
			thumbnail: images.parentActivities,
			title: "Parents/Families Activities",
			title_arabic: "أنشطة أولياء الأمور ",
			route:'parent_activities'
		},

		{
			type: "Channel",
			thumbnail: images.fictional,
			title: "Animated Fictional Stories",
			title_arabic: "القصص المتحركة مغامرات الثعلب والنسر والجمل",
			route:'fictional_story'


		},
		{
			type: "Channel",
			thumbnail:images.pdPlans,
			title: "Facilitator PD Plans",
			title_arabic: "التنمية المهنية للميسرات",
			route:'pd_videos'

		},
	];
	return (
		<section className={`${classes.footer} p-inline p-block`}>

			<div className={`${classes.footer_text}`}>
				<h1 className={`f-32 bold mb-3`}>{t("STEM-EY Digital Library")}</h1>
				<p className={`f-18 meduim`}>
					{t("STEM programming encourages and supports children in making a meaning out of their curiosity, exploration and experiences")}
				</p>
			</div>

			<div className={"d-flex flex-wrap"}>
				{items.map((item, index) => (
					<ChannelCard item={item} key={index} custom={true}/>
				))}
			</div>
		</section>
	);
};
export default SectionFooter;
