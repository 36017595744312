import React, { Fragment, useEffect, useState } from "react";
import classes from "./index.module.scss";
import ActivityCard from "../../components/ActivityCard";
import SectionGuidanceContent from "./sections/SectionGuidanceContent";
import SectionMain from "./sections/SectionMain";
import { useDispatch, useSelector } from "react-redux";
import { API_getCaregiverData } from "../../store/actions/caregiverActions";
import images from "../../resources/images";
import AuthLayout from "../../layouts/AuthLayout";

const imgs = [images.baby1, images.baby2, images.baby3, images.baby4, images.baby5, images.baby6, images.baby7, images.baby8, images.baby9, images.baby10];
const Caregiver = () => {
  const [loading, setLoading] = useState(true);
  const [caregiverData, setCaregiverData] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer.token);

  useEffect(async () => {
    setLoading(true);
    const res = await dispatch(API_getCaregiverData());
    const tempData = res.data.data.filter((categ, index) => {
      categ.img = imgs[index];
      return categ;
    });
    setCaregiverData(tempData);
    setLoading(false);
  }, [token]);

  return (
    <AuthLayout>
      <SectionMain />
      <SectionGuidanceContent loading={loading} caregiverData={caregiverData} />
    </AuthLayout>
  );
};
export default Caregiver;
