import React, { useState } from "react";
import SignIn from "../SignIn";
import EmailInput from "../../components/Inputs/EmailInput";
import PasswordInput from "../../components/Inputs/PasswordInput";
import classes from "./index.module.scss";
import { Alert, Button, Form } from "react-bootstrap";
import { regex } from "../../helpers/regex";
import { useDispatch } from "react-redux";
import {
  API_loginWithEmailAndPassword,
  setName,
  setToken,
} from "../../store/actions/authActions";
import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { setAlert } from "../../store/actions/globalAlertActions";
import { useTranslation } from "react-i18next";
  
  export default function SignInView({ changeView, closeModal,validationMessage, setValidationMessage }) {
  //states
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [isValid, setIsValid] = useState({ email: false, password: false });
  const dispatch = useDispatch();
  const [errors,setErrors] = useState({});
  // methods
    const {t} = useTranslation()
  //those two fuctions for handling change in email and password inputs value

  const handleEmailChange = (e) => {
    const isValid = regex.email.test(e.target.value);
    setEmailValue((prev) => e.target.value);
    setIsValid((prev) => ({
      ...prev,
      email: isValid,
    }));
    !isValid ?  setErrors(prev=>({...prev,email:t("email validation")})):setErrors(prev=>({...prev,email:""}))

  };
  const handlePasswordChange = (e) => {
    
    setPasswordValue(e.target.value);
    setIsValid((prev) => ({ ...prev, password: regex.password.test(e.target.value) }));
  };
  //this for login view
  const handleSignInSubmit = async (event) => {
    event.preventDefault();
    const res = await dispatch(
      API_loginWithEmailAndPassword({
        email: emailValue,
        password: passwordValue,
      })
    );

    if (res.status == 200) {
      localStorage.setItem("token", res.data.token);
      dispatch(setToken(res.data.token));
      dispatch(setName(res.data.name));
      closeModal();
      dispatch(setAlert({display:true,variant:"success", message:t("logged successfully")}))
    }
    return res;
  };

  return (
    <SignIn
      title={t("Sign in")}
      phrase={t("Sign in Description")}
      handleSubmit={handleSignInSubmit}
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
    >
      <EmailInput
        id={"email"}
        name={"email"}
        value={emailValue}
        onChange={handleEmailChange}
        errorMessage={errors.email}
        label={t("email")}
        placeholder={t("email placeholder")}
      />
      <PasswordInput
        id={"password"}
        name={"password"}
        value={passwordValue}
        onChange={handlePasswordChange}
        label={t("password")}
        placeholder={t("password placeholder")}

      />
      <Form.Group className={["mb-3", classes.rememberMe].join(" ")}>
        <Button variant="link" onClick={() => changeView("forgotPassword")}>
          {t("Forgot Password")}
        </Button>
      </Form.Group>
      <Button
        type="submit"
        className={classes.submitBtn}
        disabled={!isValid.email || !isValid.password}
      >
        {t("login")}
      </Button>
    </SignIn>
  );
}
