import { constants } from "../constants";

const initialState = { token: "", name: "" };
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_NAME:
      return { ...state, name: action.payload };

    case constants.SET_TOKEN:
      return { ...state, token: action.payload };
    default:
      return state;
  }
}
