import { isFocusable } from "@testing-library/user-event/dist/utils";
import { t } from "i18next";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Buttons";
import { API_postCaregiverChildName } from "../../../store/actions/caregiverActions";
import classes from "./Form.module.scss";
import FormItem from "./FormItem";
import { useReactToPrint } from "react-to-print";

// import { useReactToPrint } from 'react-to-print';

// import { ComponentToPrint } from './ComponentToPrint';

const CustForm = (props) => {
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const token = useSelector((state) => state.authReducer.token);
	const [childName, setChildName] = useState("");
	const componentRef = useRef();

	// const print = () => {
	// 	var printdata = document.getElementById("form");
	// 	const newwin = window.open("");
	// 	newwin.document.write(printdata.outerHTML);
	// 	newwin.print();
	// 	newwin.close();
	// };

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		if (props.child_name) setChildName(props.child_name);
	}, [props.child_name]);

	const setChildNameToAPI = (e) => {
		setChildName(e.target.value);
		if (token) dispatch(API_postCaregiverChildName(e.target.value, props.id));
	};

	return (
		<div className={classes.form_container}>
			<div className={classes.column}>
				<h5 className="f-18 semi_bold">{t("What most babies do by this age")}</h5>
				{/* <button onClick={print}> Print</button> */}
				<Button onClick={handlePrint} route="./" text={t("Print")} blue={true} />
			</div>
			<div className={classes.form} ref={componentRef}>
				<div className={classes.NameInput}>
					<input value={childName} onChange={setChildNameToAPI} placeholder={t("Child Name")} />
				</div>
				{props.group.map((element) => (
					<Fragment key={element.title}>
						<h6 className={`${classes.inner_header} semi_bold f-18`}>{lang == "en" ? element.title : element.title_arabic}</h6>
						{element.items.map((item) => (
							<FormItem key={item.id} item={item} />
						))}
					</Fragment>
				))}
			</div>
		</div>
	);
};

export default CustForm;
