import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import classesEn from "./index.module.scss";
import classesAr from "./indexAr.module.scss";
import images from "../../resources/images";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const [classes, setClasses] = useState(classesEn);
	const changeLang = () => {
		switch (lang) {
			case "en":
				document.querySelector("html").setAttribute("lang", "ar");
				localStorage.setItem("lang", "ar");
				window.location.reload(false);

				break;
			case "ar":
				document.querySelector("html").setAttribute("lang", "en");
				localStorage.setItem("lang", "en");
				window.location.reload(false);
				break;
		}
	};
	const getLang = () => {
		switch (lang) {
			case "en":
				return "تغيير إلى العربية";
			case "ar":
				return "Change to English";
		}
	};
	useEffect(() => {
		setClasses(lang == "ar" ? classesAr : classesEn);
	}, [lang]);

	return (
		<React.Fragment>
			<footer className="p-inline col-12 p-block">
				<div className="row align-items-start gx-0">
					{/* <ul className="col-md-5">
						<li id="logo" className={`${classes.m_bottom} navbar-brand`}>
							<img src={images.footerLogoMoss} />
						</li>
						 <li>
							{t("Welcome to STEMEY - PLJ  platform! Embark on the Children's journey as they start exploring the world around them")}
						</li>
					</ul> */}
					<div className={"col-md-7" }>
						<div className="row justify-content-end gx-0">
							<div className="col-md-4">
								<h3 className={`f-18 bold ${classes.m_bottom}`}>{t("Useful Links")}</h3>
								<ul className={classes.ul}>
									<li className={classes["list_item"]}>
										<NavLink to="./caregiver" className={classes["list_item"]}>
											{t("Caregiver Resources")}
										</NavLink>
									</li>
									<li className={classes["list_item"]}>
										<NavLink to="./parent/stem-camp" className={classes["list_item"]}>
											{t("Parent Family Resources")}
										</NavLink>
									</li>
									<li className={classes["list_item"]}>
										<NavLink to="./facilitator/stem-camp" className={classes["list_item"]}>
											{t("Facilitator Resources")}
										</NavLink>
									</li>
									<li onClick={changeLang} className={classes["list_item"]}>
										{getLang()}
									</li>
								</ul>
							</div>
							<div className="col-md-6">
								<h3 className={`f-18 bold ${classes.m_bottom}`}>{t("Sponsored By")}</h3>
								<ul className={classes["list--logos"]}>
									<li>
										<img className="img-fluid" src={images.deLogoWhite} alt="Discovery Education Logo" />
									</li>


									<li>
										<img className="img-fluid" src={images.socialSolidarity} alt="Social Solidarity Logo" />
									</li>
									<li>
										<img className="img-fluid" src={images.nationalChildhood} alt="national Childhood Logo" />
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div>
				<span className="f-14 regular text-blue w-100 text-center">{t("©2022 Discovery Education Egypt. All Copyrights reserved")}</span>
			</div>
		</React.Fragment>
	);
};

export default Footer;
