import React from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import images from "../../resources/images";

// props - white
// props - hasIcon
// props - blue
// props - text
const Button = ({ white, hasIcon, blue, text, route, children, onClick }) => {
  return (
    <div onClick={onClick}>
      <Link to={route} className={`${classes.btn} ${white ? classes.btn_white : blue ? classes.btn_blue : ""}`}>
        <div className={`${classes.btn_text} ${hasIcon || blue ? classes.btn_text_custom : ""}`}>
          {children ? children : <p className={classes.btn_text_content}>{text ? text : "Start Now"}</p>}
        </div>
        {hasIcon ? (
          <div className={classes.btn_arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="11.576" height="20.518" viewBox="0 0 11.576 20.518">
              <g transform="translate(-117.166 0.576)">
                <g transform="translate(117.742 0)">
                  <path
                    d="M128.021,9.188,119.028.22a.754.754,0,0,0-1.064,1.067l8.457,8.434-8.458,8.434a.754.754,0,0,0,1.065,1.067l8.993-8.967a.753.753,0,0,0,0-1.067Z"
                    transform="translate(-117.742 0)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="1"
                  />
                </g>
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
      </Link>
    </div>
  );
};
export default Button;
