import React, { useEffect, useState } from "react";
import images from "../../../resources/images";
import { useTranslation } from "react-i18next";
import classes from "./SectionAbout.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FilterDropdown from "../components/FilterDropdown";
import Circle from "../components/Circle";
const SectionAbout = () => {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const {hash,key} = useLocation()
	const resources=[
		{
			title:'12',
			title_arabic:'١٢',
			desc:'Modules',
			desc_arabic:'وحدة'
		},

		{
			title:'Children Guide',
			title_arabic:"دليل الأطفال للقراءة",
			desc:'Oral Language development tools',
			desc_arabic:'أسئلة وأدوات تطوير اللغة الشفهية'
		},
		{
			title:'Together Practices',
			title_arabic:'الممارسات المشتركة "هيا معًا"',
			desc:'The interactions between adults & children',
			desc_arabic:"أنشطة ينفذها الكبار مع الأطفال"
		},
		{
			title:"Domains & Supportive Practices",
			title_arabic:"المجالات والممارسات الداعمة",
			desc:'Developmental benchmarks guide ',
			desc_arabic:"المقاييس التنموية"
		},
		{
			title:"Parent and Family Engagement Activities",
			title_arabic:"أنشطة أولياء الأمور",
			desc:'collaborative relation building activities ',
			desc_arabic:"بناء العلاقات التعاونية"
		},

	]

	useEffect(() => {
		if (hash) {
			let el = document.getElementById(hash.slice(1))
			el.scrollIntoView()
		}
	},[hash,key])

	return (
		<section className={`${classes.aboutSection} p-inline`} id="aboutStem">
			<div className={classes.title}>
				<h1 className={`f-32 bold`}>{t("Egyptian STEM Early Years Program Design")}</h1>
				<p className="f-18 light">{t("Welcome to STEMEY - PLJ  platform! Embark on the Children's journey as they start exploring the world around them")}</p>

			</div>
			<div className={classes.circleWrapper}>
					{resources.map((item, index)=>(
						<Circle title={lang=="en"?item.title:item.title_arabic} desc={lang=="en"?item.desc:item.desc_arabic} index={index} key={`circle-${index}`}/>
					))}
				</div>





		</section>
	);
};
export default SectionAbout;
