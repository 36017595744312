import React from 'react'
import { Navigate } from "react-router-dom";
export default function AuthLayout({children}) {
    if(!localStorage.getItem("token")){
        return <Navigate to="/home"/>
    }
  return (
    <>{children}</>
  )
}
