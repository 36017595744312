import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import classes from "./index.module.scss";
import { useTranslation } from "react-i18next";
import CustModal from "../../components/Modal";
import { handleModal } from "../../store/actions/signInActions";
import SignIn from "../../components/SignIn";

import LoginViews from "../../components/LoginViews";
import GlobalAlert from "../../components/GlobalAlert";

const DefaultLayout = (props) => {
	const { t } = useTranslation();
	const [scroll, setScroll] = useState(true);
	const lang = useSelector((state) => state.settingsReducer.lang);
	const [width, setWidth] = React.useState(window.innerWidth);
	const signInModal = useSelector(state=> state.signInReducer);
	const token = useSelector(state=>state.authReducer.token);
	const dispatch = useDispatch();
	const closeLogInModal = ()=>{
		if(localStorage.getItem("token")){
			dispatch(handleModal(false))
		}
	}
	const updateWidthAndHeight = () => {
		setWidth(window.innerWidth);
	};
	useEffect(()=>{
		if(!localStorage.getItem("token")){
			dispatch(handleModal(true))
		}
	},[token])
	useEffect(() => {

		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
			// console.log("window", window.innerWidth);
		});
		return () => window.removeEventListener("resize", setWidth(window.innerWidth));
	}, [width]);
	return (
		<React.Fragment>
			<CustModal
				show={signInModal.show}
				onHide={closeLogInModal}
				isReading={true}
				noModalHeader={true}
				noCloseIcon={true}
				modalClassName={classes.signInModal}
				headerClassname={classes.modalTitle}
			>
				<LoginViews closeModal={closeLogInModal}/>
			</CustModal>
			<Header scroll={scroll} width={width}></Header>
			<main>
				{props.children}
				{/* <GlobalAlert /> */}
			</main>
			<Footer />
		</React.Fragment>
	);
};
export default DefaultLayout;
