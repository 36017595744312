export const translation_en = {
	"Program design core elements": "Program design core elements",
	"Collaborative relationships between parents/families with their children & classroom educators.":
		"Collaborative relationships between parents/families with their children & classroom educators.",
	"Developmental benchmarks guide & indicators for what children should know and be able to do, with adult support.":
		"Developmental benchmarks guide & indicators for what children should know and be able to do, with adult support.",
	"The interactions between adults and children and includes 8 key together practices.":
		"The interactions between adults and children and includes 8 key together practices.",
	"Language development tools to support children in developing communication skills.":
		"Language development tools to support children in developing communication skills.",
	"Egyptian STEM Early Years Program Design": "Egyptian STEM Early Years Program Design",
	"Central to the design are the themes, which are based on a variety of STEM-based topics and will be presented in 12 modules throughout the year (one per month).":
		"Central to the design are the themes, which are based on a variety of STEM-based topics and will be presented in 12 modules throughout the year (one per month).",
	"Together Practices": "Together Practices",
	"The interactions between adults & children": "The interactions between adults & children",
	"Children Guide": "Children Guide",
	"Oral Language development tools": "oral language development prompts",
	"Parent Family Guide": "Parent and Family Engagement Activities",
	"Domains and Supportive Practices": "Domains and Supportive Practices",
	"collaborative relation building activities": "collaborative relation building activities",
	"Developmental benchmarks guide": "Developmental benchmarks guide",
	"STEM Program Developmental Levels": "STEM Program Developmental Levels",
	"Caregiver Resources": "Caregiver Resources",
	"Facilitator Resources": "Facilitator Resources",
	"Parent Family Resources": "Parent Family Resources",
	"Egyptian STEM for Early Years Program": "Egyptian STEM for Early Years Program",
	"Helping children from 0 - 4 years old to explore, learn, and grow in ways that prepare them for future success early.":
		"Helping children from 0 - 4 years old to explore, learn, and grow in ways that prepare them for future success early.",
	"The design of the program is centered on 2 core elements": "The design of the program is centered on 2 core elements",
	"Thematic Instruction": "Thematic Instruction",
	Collaboraion: "Collaboraion",
	"Thematic instruction which are based on a variety of STEM-based topics.":
		"Thematic instruction which are based on a variety of STEM-based topics.",
	"Fostering of collaborative relationships among classroom educators & parents/families.":
		"Fostering of collaborative relationships among classroom educators & parents/families.",
	"From the day they are born, children are natural explorers, scientists, and learners. Parents and caregivers confirm that children love to experiment, observe, and explore. STEM, which is an international acronym meaning science, technology, engineering, and math, is a perfect fit for the curiosity and imagination of children.":
		"From the day they are born, children are natural explorers, scientists, and learners. Parents and caregivers confirm that children love to experiment, observe, and explore. STEM, which is an international acronym meaning science, technology, engineering, and math, is a perfect fit for the curiosity and imagination of children.",
	"STEM-Based Themes": "STEM-Based Themes",
	"Structured Play & Hands-on Learning": "Structured Play & Hands-on Learning",
	"STEM Together Practices": "STEM Together Practices",
	"Unlock a whole new teaching and learning experience with STEM practices built for your Children developmental needs":"Welcome to STEMEY - PLJ  platform! Embark on the Children's journey as they start exploring the world around them",
	"Sponsored By": "Sponsored By",
	"Useful Links": "Useful Links",
	"8 weekly together practices": "8 weekly together activities",
	"Facilitator Guide": "Facilitator Guide",
	"Children Guide": "Children Guide",
	"3 Together activities/Practices per day.": "3 Together activities/Practices per day.",
	"Suggested weekly activity": "Suggested weekly activity",
	"Separate instructions for observers and explorers.": "Separate instructions for observers and explorers.",
	"4 nonfictional Texts (1 per week, to be read aloud during “Read Together” activity)": "4 nonfictional Texts (1 per week, to be read aloud during “Read Together” activity)",
	"1 fictional story (1 per module).": "1 fictional story (1 per theme, to be read during the Circle time or dismissal).",
	Week: "Week",
	"Central to the design are the themes, which are based on a variety of STEM-Based topics and will be presented in 12 modules throughout the year (one per month).":
		"Central to the design are the themes, which are based on a variety of STEM-Based topics and will be presented in 12 modules throughout the year (one per month).",
	"Your child’s early years are so important. Tracking how your little ones plays, learns, speak, acts, and moves helps you support their development":
		"Your child’s early years are so important. Tracking how your little ones plays, learns, speak, acts, and moves helps you support their development",
	"Interested in Saving your Child Checklist? Sign in with Google!": "Interested in Saving your Child Checklist? Sign in with Google!",

	"Caregiver Guidance": "Caregiver Guidance",
	Observers: "Sprouts",
	Explorers: "Flowers",
	Discover: "Discover",
	"Start Now": "Start Now",

	"Take a deep dive into resources": "Take a deep dive into resources",

	"Track your child’s developmental progress by looking for developmental milestones using an interactive, illustrated checklist.":
		"Track your child’s developmental progress by looking for developmental milestones using an interactive, illustrated checklist.",

	"Help your baby learn and grow by applying what you can do with them at his developmental milestone. Talk, read, sing, and play together every day. ":
		"Help your baby learn and grow by applying what you can do with them at his developmental milestone. Talk, read, sing, and play together every day. ",

	"Milestones Matter!": "Milestones Matter!",
	"How your child plays, learns, speaks, acts, and moves offers important clues about his or her development.":
		"How your child plays, learns, speaks, acts, and moves offers important clues about his or her development.",

	"Ignite your Observers Curiosity!": "Ignite your Observers Curiosity!",

	"Engage with a variety of STEM Together Practices designed to foster a cultural shift around STEM teaching and learning.":
		"Engage with a variety of STEM Together Practices designed to foster a cultural shift around STEM teaching and learning.",

	"12 Module per year": "12 Module per year",
	"1 Module per month": "1 Module per month",
	"8 Together Practicies per week": "8 Together Practicies per week",

	"Adults support helping children age from 0-24 months to explore, learn, and grow in interactive ways . Caregiver Guidance has been provided.":
		"Adults support helping children age from 0-24 months to explore, learn, and grow in interactive ways . Caregiver Guidance has been provided.",
	"©2022 Discovery Education Egypt. All Copyrights reserved": "© 2022 Discovery Education Egypt. All Copyrights reserved",
	"birth - 24 Months": "Age 0 - 24 Months",
	"Me & My Community:Telling Our Story Through Shapes & Colors.": "Me & My Community:Telling Our Story Through Shapes & Colors.",
	"Explore": "Explore",
	"About STEMEY": "About STEMEY",
	"Parent Program": "Parent Program",
	"Facilitator Program": "Facilitator Program",
	"Caregiver Program": "Caregiver Program",
	"Sign in":"Sign in",
	"Sign in Description":"Build skills for today, tomorrow, and beyond.",
	"email":"Email",
	"password":"password",
	"New Password": "New Password",
	"Reenter password":"Re-Enter New Password",
	"email placeholder":"Enter Your Email",
	"password placeholder": "Enter your password",
	"confirm password":"Confirm New Password",
	"Forgot Password":"Forgot password?",
	"Forgot Password title":"Reset Forgot password",
	"forgot password description":"Don’t worry we will help you to restore it",
	"login":"Login",
	"send":"send",
	"Verify Email":"Verify Email",
	"Verify email descripiton":"Check your mail box you will find otp",
	"reset":"Reset",
	"logout":"log out",
	"email validation":"Enter a valid email address",
	"password validation":"password should be from 5 to 12 characters",
	"reEnter password validation":"the two fields should match",
	"logged successfully":"You logged in successfully",
	"Enjoy doing fun and easy activities every day with children chicklists":" To have a unique experience with STEM early years Program in your nursery  . Explore - How to Use This Guide. - Nursery Culture Guidance. - Morning Circle Time. - Nursery Schedule - Appendix"

};
