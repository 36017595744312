import React, { useEffect, useRef, useState } from "react";
import classes from "./index.module.scss";
import { Col} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";



const VideoCard = ({ item, type, inPlace, wrapper }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const videoEl = useRef(null);
	const seekTime1 = useSelector(state => state.activityVideoReducer.time);
	const previewThumbnail = lang=="en"?item?.thumbnail:item?.thumbnail_ar;

	let markers = item?.video_segmentations?.map((segment)=>{
				return {
					label:lang == "en"? segment.activity:segment.activity_arabic,
					time:Number(lang=="en"?segment.start:segment.start_ar),
					color:'#ffc837'
				};
			})

	const videoOptions = {
		previewThumbnails:{enabled:previewThumbnail != undefined , src:Boolean(previewThumbnail) ? previewThumbnail:""},
		markers: { enabled: true, points: markers },
		};

	const seekVideoToActivity = ()=>{
		if(seekTime1){
			const playPromise = videoEl.current.plyr.play();
			if(playPromise !== undefined){
				playPromise.then(res=>{
					videoEl.current.plyr.currentTime = seekTime1;
					console.log(videoEl.current.plyr.currentTime);

				}).catch(err=>{
					console.log("err",err);
				})
			}
		}
	}

	useEffect(()=>{
		if(videoEl?.current && Number(seekTime1) ){
			setTimeout(()=>{
				seekVideoToActivity();
			},50)
		}
		return ()=>{
			videoEl.current.plyr.destroy()
		}
	},[seekTime1,videoEl?.current])
	return (
		<React.Fragment>
			<Col
				className={`mx-auto ${classes.card}`}>
					<div className={classes.relative}>
						<Plyr
						ref={videoEl}
						source={{
						type: "video",
						sources: [
							{
							src: lang == "en" ? item?.media : item?.media_arabic,
							}
						]
						}}
						options={videoOptions}
						/>
					</div>
					<div className={`${classes.resource_content}`}>
						{wrapper&&<span className="f-14 semi_bold">{wrapper}</span>}
						<p className="f-16 semi_bold text-dark">{lang == "en" ? item?.title : item?.title_arabic}</p>
					</div>
			</Col>


		</React.Fragment>
	);
};

export default VideoCard;
