import React, { useEffect, useState } from "react";
import { Nav, Dropdown, Container, Button, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classes from "./index.module.scss";
import images from "../../resources/images";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isDOMComponent } from "react-dom/test-utils";
import { setName, setToken } from "../../store/actions/authActions";
import Menu from "../Menu/Menu";
import Images from "../../resources/images";
import { handleModal } from "../../store/actions/signInActions";
import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import {setAlert} from "../../store/actions/globalAlertActions"
const Header = ({ scroll, width }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector((state) => state.authReducer.token);
  const name = useSelector((state) => state.authReducer.name);
  const lang = useSelector((state) => state.settingsReducer.lang);
  const [isOpen, setIsOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(false)




  const getLang = () => {
    switch (lang) {
      case "en":
        return "العربية";
      case "ar":
        return "English";
    }
  };

  const changeLang = () => {
    switch (lang) {
      case "en":
        document.querySelector("html").setAttribute("lang", "ar");
        localStorage.setItem("lang", "ar");
        window.location.reload(false);

        break;
      case "ar":
        document.querySelector("html").setAttribute("lang", "en");
        localStorage.setItem("lang", "en");
        window.location.reload(false);
        break;
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const openMenu = ()=>{
    setIsOpen(true);
  }

  const closeMenu = ()=>{
    if(isOpen == true){
      setIsOpen(false);
    }
  }

	const openSignInModal = ()=>{
    if(!token){
      dispatch(handleModal(true))
    }
	}
  const logOut = async () => {
    try{
      const res = await axios.post(`${baseUrl}${endpoints.logout}`,{},{
        headers:{
          Authorization:`Token ${token}`
        }
      });
      console.log("res logout", res);
      dispatch(setAlert({display:true, variant:"success", message: res.data.message}))
    }catch(err){
      console.log("err", err);
    }
    localStorage.removeItem("googleData");
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    dispatch(setToken(""));
    dispatch(setName(""));
  };

  return (
    <>
     {isOpen ? (
    <div className={`overlay header--overlay ${isOpen || activeButton ?'active':''}`} onClick={closeMenu}></div>
     ):null}
    <header className={`${classes.fixed} shadow p-inline`}>
      <Container fluid className={`px-0 ${classes.wrapper}`}>
        <NavLink to="/" className={`${classes.navbar_logo}`} onClick={width < 991 ? closeMenu  : () => {}}>
          <img src={lang == "en" ? images.moseyLogo: images.moseyLogoAr}/>
        </NavLink>
          <Menu activeButton={activeButton} setActiveButton={setActiveButton} setIsOpen={setIsOpen}/>


        <button className={`${scroll ? "dark" : ""} d-block d-lg-none ${classes.toggle_btn}`} onClick={toggleMenu}>
          {scroll || (isOpen && width < 992) ? <images.darkBars /> : <images.bars />}
        </button>

        <Nav className={`align-items-center ${classes.navBar__nav} ${isOpen ? classes.show : ""}`}>
          <NavLink to="/home/#aboutStem"  onClick={closeMenu} className={`${(state) => (state.isActive ? "active" : null)} ${classes.nav_item} f-16 meduim`}>{t("About STEMEY")}</NavLink>
          {isOpen && width < 992 && <hr className={`w-100`} />}

          <NavLink to="/channel/weekly_together"  onClick={closeMenu} className={`${(state) => (state.isActive ? "active" : null)} ${classes.nav_item} f-16 meduim`}>{t("Digital Library")}</NavLink>
          {isOpen && width < 992 && <hr className={`w-100`} />}


          {token !== "" ? (
            <div className={classes.userLogo}>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className={classes.avatar}>
                  {name && name.length > 0 ?
                   name[0] :<Images.user/>}
                </Dropdown.Toggle>
                <Dropdown.Menu  onClick={closeMenu}>
                  <Dropdown.Item onClick={logOut}>{t("logout")}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : <Button variant="outline-primary" className={` ${classes.loginBtn}`} onClick={()=> openSignInModal()}>{t("login")}</Button>}
          <Button className={`${classes.nav_item} ${classes.lang_btn}`} onClick={changeLang}>
            <images.localization/>
          </Button>
        </Nav>
      </Container>
    </header>
    </>

  );
};

export default Header;
