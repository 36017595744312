import React, { useState } from "react";
import { Form } from "react-bootstrap";
import images from "../../../resources/images";
import classes from "../index.module.scss";

export default function PasswordInput({
  label = "Password",
  id,
  name,
  noIcon,
  hideShowPasswordBtn,
  errorMessage,
  ...props
}) {
  const [type, setType] = useState("password");
  const togglePassword = () => {
    setType((prev) => {
      return prev == "password" ? "text" : "password";
    });
  };
  return (
    <Form.Group
      className={["mb-3", classes.inputWrapper].join(" ")}
      controlId={id}
    >
      <Form.Label>{label}</Form.Label>
      <div className={classes.inputWithIconsWrapper}>
        <Form.Control
          type={type}
          name={name}
          placeholder="Enter your password"
          className={`${!noIcon ? classes.withIcon : ""}`}
          {...props}
        />
        {!noIcon && <images.passwordIcon className={classes.icon} />}
        {!hideShowPasswordBtn && (
          <images.showPassword
            className={classes.showPassword}
            onClick={togglePassword}
          />
        )}
      </div>
      {errorMessage && (
        <p className={classes.validationMessage}>{errorMessage}</p>
      )}
    </Form.Group>
  );
}
