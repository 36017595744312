import React from "react";
import classes from "./index.module.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const WeekAccordion = ({ weeks, story, module, isParent }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const { t } = useTranslation();
	const navigate= useNavigate();
	return (
		<React.Fragment>
			<ul className={`${classes.weeksList}`} >
				{weeks?.map((week, index) => (
					<li key={`week-${index}`}>
					<a className={"d-flex flex-column mx-2 align-items-start btn"} onClick={()=>navigate(isParent?`/parent/${module}/week/${week.slug}`:`/facilitator/${module}/week/${week.slug}`)} >
						<span className="f-20 regular">{t('Week')} {`${index+1}`}</span>
						<h3 className={`f-23 bold`}>{lang=="en"?week.title:week.title_arabic}</h3>
					</a>
					</li>
				))}
			</ul>
		</React.Fragment>
	);
};

export default WeekAccordion;
