import React from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import CustomAccordionItem from "../../../components/CustomAccordionItem";
import FeaturedComponent from "../../../components/FeaturedComponent";
import images from "../../../resources/images";
import CustForm from "../components/Form";
import classes from "./SectionGuidanceContent.module.scss";
import { t } from "i18next";
import ReactLoading from "react-loading";

const SectionGuidanceContent = (props) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	return (
		<section className={`${classes["guidance-content"]} p-inline m-block`}>
			<h3 className={`bold f-32 text-center mb-5`}>{t("Developmental Milestone Checklists")}</h3>
			<p className={`meduim f-20 text-center`}>{t("Select the appropriate age-range below to view the correct checklist.")}</p>
			{props.loading ? (
				<div className={classes.loading}>
					<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
				</div>
			) : (
				<Accordion className={`${classes.accordion}`} defaultActiveKey="0" alwaysOpen>
					{props.caregiverData.map((accordionItemData, index) => (
						<CustomAccordionItem
							key={accordionItemData.title}
							title={lang == "en" ? accordionItemData.title : accordionItemData.title_arabic}
							Icon={accordionItemData.img}
							currentKey={index}
						>
							<h4 className={`semi_bold f-28`}>{t("Your Baby")}</h4>
							<p className={`light f-18`}>{lang == "en" ? accordionItemData.description : accordionItemData.description_arabic}</p>
							<CustForm
								child_name={accordionItemData.checklists.child_name}
								id={accordionItemData.checklists.id}
								group={accordionItemData.checklists.groups}
							/>
							{/* <div style="margin-left:-20px;margin-right:-20px"> */}
							<FeaturedComponent
								newTabLink={lang === "ar" ? accordionItemData.media_arabic : accordionItemData.media}
								mainData={
									<img style={{ width: "100%" }} src={lang === "ar" ? accordionItemData.media_arabic : accordionItemData.media} />
								}
								image={images.bluePattren}
								icon={<images.caregiver />}
								btnText={t("Learn More About Activities")}
								noModalHeader={true}
							>
								<h3 className="bold f-28">{t("How can you help your child learn and grow?")}</h3>
								<p className="f-16 meduim">{t("Enjoy doing fun and easy activities every day with your child")}</p>
							</FeaturedComponent>
						</CustomAccordionItem>
					))}
				</Accordion>
			)}
		</section>
	);
};

export default SectionGuidanceContent;
