import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import classes from "./FilterDropdown.module.scss";
import { t } from "i18next";
import Images from "../../../resources/images";
import { useNavigate } from "react-router-dom";

const FilterDropdown = (props) => {
const navigate=useNavigate();
  const lang = useSelector((state) => state.settingsReducer.lang);
  const facilitatorModules=useSelector((state)=>state.facilitatorReducer.facilitatorModules);
  const parentModules=useSelector((state)=>state.facilitatorReducer.parentModules);
  const[program, setProgram]=useState({title:'facilitator', slug:'facilitator'});
  const[module, setModule]=useState({title:'', slug:'', weeks:[]});

  const programs=[
      {
          title:'facilitator',
          title_ar:'الميسرة',
          slug:'facilitator'
      },
      {
          title:'parent',
          title_ar:'ولي أمر',
          slug:'parent'
      }
  ]
  const navigateToProgramPage=(data)=>{
      navigate(`/${program.slug}/${module.slug}/week/${data}`)

  }
  const handleSetProgram = (item)=>{
    setProgram(item)
  }
  return (
    <div className={`${classes.filterDropdownWrap} shadow`}>
        <Dropdown className={classes.filterDropdown}>
            <Dropdown.Toggle id="dropdown-button-programs" >
            <div>
                <span className="f-12 bold">{t("Explore As")}</span>
                <h3 className="f-20 bold mb-0">{t(program.title)}</h3>
            </div>

            <Images.paginationArrow/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {programs.map((item, index)=>(
                <Dropdown.Item onClick={()=>handleSetProgram(item)} key={`program-${index}`}>
                    {lang=="en"?item.title:item.title_ar}
                </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown className={classes.filterDropdown}>
            <Dropdown.Toggle id="dropdown-button-modules">
            <h3 className="f-16 bold text-blue mb-0">
                {module.title? lang=="en"?module.title:module.title_arabic: t("Select Theme")}

                </h3>
            <Images.paginationArrow/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {program.title=='facilitator'?
                facilitatorModules.map((module, index)=>(
                    <Dropdown.Item onClick={(e)=>{
                        if(module.published_dev){
                            setModule(module)
                        }  else{
                            e.preventDefault()
                        }
                    }}
                    key={`module-${index}`}
                    className={`${!module.published_dev ? classes.dimmed:""}`}
                    >
                        {lang=="en"?module.title:module.title_arabic}
                    </Dropdown.Item>
                )):
                parentModules.map((module, index)=>(
                    <Dropdown.Item onClick={(e)=>{
                            if(module.published_dev){
                                setModule(module)
                            }  else{
                                e.preventDefault()
                            }
                        }}
                        key={`module-${index}`}
                        className={`${!module.published_dev ? classes.dimmed:""}`}>
                        {lang=="en"?module.title:module.title_arabic}
                    </Dropdown.Item>
                ))
}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown className={classes.filterDropdown}>
            <Dropdown.Toggle id="dropdown-button-weeks">
                <h3 className="f-16 bold text-blue mb-0">{t("Select Week")}</h3>
                <Images.paginationArrow/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {module?.weeks?.map((item, index)=>(
                <Dropdown.Item  key={`week-${index}`} onClick={()=>navigateToProgramPage(item.slug)}>
                    {lang=="en"?item.title:item.title_arabic}

                </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>

    </div>

  );
};
export default FilterDropdown;
