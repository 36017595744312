import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import images from "../../resources/images";
import Form from "react-bootstrap/Form";
import { Alert, Button } from "react-bootstrap";
import errorMessageFromObject from "../../helpers/errorMessageFromObject";

export default function SignIn({
  title,
  phrase,
  handleSubmit,
  children,
  changeView,
  showBackBtn,
  secondaryTitle,
  validationMessage, setValidationMessage,
  isValid
}) {
  const [validated, setValidated] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const res =await handleSubmit(e);
    if(res.status !== 200){
      console.log(res.message);
      setValidationMessage({
        display: true,
        message: errorMessageFromObject(res?.response?.data) || res?.data?.error,
        variant: "danger",
      });
    }
    else if(res.status == 200 && res.data.message){
      setValidationMessage({
        display: true,
        message: res.data?.message || "Success",
        variant: "success",
      });
    }
    // if res.status !== 200 set validation message else close modal and sign him in
  };
  return (
    <div className={classes.signIn}>
      <div className={classes.header}>
        <div className={classes.info}>
          {showBackBtn ? (
            <Button variant="link" className={classes.backBtn} onClick={() => changeView()}>
              <images.greyBackArrow /> <span>Back</span>
            </Button>
          ) : null}
          <h1 className={`${secondaryTitle ? classes.secondaryTitle : ""}`}>
            {title}
          </h1>
          <p>{phrase}</p>
        </div>
        <div className={classes.logo}>
          <img src={images.signInImage} alt={phrase} />
        </div>
      </div>
      {validationMessage.display && (
        <Alert
          variant={validationMessage.variant}
          className={`${
            validationMessage.variant == "danger" ? classes.alertError : ""
          }`}
        >
          {validationMessage.variant == "danger" ? (
            <span>{<images.errorIcon />}</span>
          ) : null}
          <span>{validationMessage.message}</span>
        </Alert>
      )}
      <Form noValidate onSubmit={onSubmit}>
        {children}
      </Form>
    </div>
  );
}
