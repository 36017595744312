import React, { Fragment, useEffect } from "react";
import SectionAbout from "./sections/SectionAbout";
import SectionLanding from "./sections/SectionLanding";
import SectionProgramLevels from "./sections/SectionProgramLevels";
import SectionFooter from "./sections/SectionFooter";

const Home = () => {
  useEffect(() => {}, []);
  return (
    <div style={{background:'#F0F0F0'}}>
      <SectionLanding />
      <SectionAbout />
      <SectionProgramLevels />
      <SectionFooter />
    </div>
  );
};
export default Home;
