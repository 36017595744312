import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Parent from "./pages/Parent";
import Caregiver from "./pages/Caregiver";
import Facilitator from "./pages/Facilitator";
import Channel from "./pages/Channel";
import DefaultLayout from "./layouts/Default";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "./store/actions/settingsActions";
import { useEffect } from "react";
import { setName, setToken } from "./store/actions/authActions";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import React4GA from "react-ga4";
import {API_getModuleNames} from "./store/actions/facilitatorActions"
import FacilitatorWeek from "./pages/FacilitatorWeek";
import ParentWeek from "./pages/ParentWeek";

function App() {
	const dispatch = useDispatch();
	const location = useLocation();
	const token = useSelector(state=> state.authReducer.token)

	// universal analytics
	useEffect(() => {
		ReactGA.initialize("UA-180544073-1");
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	// google analytics 4

	useEffect(() => {
		React4GA.initialize(" G-X7XECSHS8E");
		React4GA.send({ hitType: "pageview", page: location.pathname });
	}, [location]);

	useEffect(() => {
		const lang = localStorage.getItem("lang");
		dispatch(setLang(lang !== "ar" ? "en" : "ar"));
		document.querySelector("html").setAttribute("lang", lang !== "ar" ? "en" : "ar");
		document.getElementsByTagName("html")[0].setAttribute("dir", lang !== "ar" ? "ltr" : "rtl");

		const name = localStorage.getItem("name");
		const token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

		dispatch(setToken(token));
		dispatch(setName(name));

	}, []);

	useEffect(()=>{
		 dispatch(API_getModuleNames());
	},[token])
	return (
		<DefaultLayout>
			<Routes>
				<Route path="/" element={<Navigate to="/home" replace />} />
				<Route path="/home" element={<Home />} />
				<Route path="/parent/:slug" element={<Parent />} />
				<Route path="/caregiver" element={<Caregiver />} />
				<Route path="/facilitator/:slug" element={<Facilitator />} />
				<Route path="/facilitator/:module/week/:week" element={<FacilitatorWeek />} />
				<Route path="/parent/:module/week/:week" element={<ParentWeek />} />

				<Route path="/channel/:slug" element={<Channel />} />

			</Routes>
		</DefaultLayout>
	);
}

export default App;
