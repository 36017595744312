import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { constants } from "../constants";
import { removeUnauthedToken } from "../../helpers/removeUnauthedTokens";
export const API_getParentData = (module) => {
  return async () => {
    try {
      const response = await axios.get(`${baseUrl}/parent/${module}`, {
        headers: localStorage.getItem("token")
            ? {
                Authorization: "Token " + localStorage.getItem("token"),
              }
            : {},
      });
      return response;
    } catch (error) {
      if(error?.response?.status === 401){
				removeUnauthedToken()
			}
      return error.response;
    }
  };
};

export const API_getParentWeeks = (module, week) => {
  return async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/parent/${module}/week/${week}`,
        {
          headers: localStorage.getItem("token")
            ? {
                Authorization: "Token " + localStorage.getItem("token"),
              }
            : null,
        }
      );
      return response;
    } catch (error) {
      if(error?.response?.status === 401){
				removeUnauthedToken()
			}
      return error.response;
    }
  };
};
