import React, { useState, useEffect } from "react";
import classes from "./index.module.scss";
import images from "../../resources/images";
import { Col, Row, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { translation_ar } from "../../localization/langs/ar";
import { API_getModuleNames } from "../../store/actions/facilitatorActions";
import { useLocation, useNavigate,NavLink } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import whiteArrow from "../../resources/assets/icons/w-left-arrow.svg"



const CustomHeroSection = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const token = useSelector((state) => state.authReducer.token);
	const { t } = useTranslation();
	const [loading, setLoading]=useState(false)
	const[moduleNames, setModuleNames]=useState([])
	const [dropdownBtnTitle, setDropdownBtnTitle]=useState()
	const navigate = useNavigate()
	const facilitator = useSelector((state) => state.facilitatorReducer.facilitatorModules);
  const parent = useSelector((state) => state.facilitatorReducer.parentModules);
	const [crumbs, setCrumbs] = useState(location.pathname.split('/'))
	const programs = [
					{
							title: "Facilitator Program",
							title_arabic: 'برنامج الميسرة',
							slug: 'facilitator'
					},
					{
							title: 'Parent Program',
							title_arabic: 'برنامج ولي الأمر',
							slug: 'parent'
					}
			];
	const [currentProgram, setCurrentProgram] = useState(() => {
		return programs.filter(program => program.slug === crumbs[1])[0]
	})
	const [currentModule, setCurrentModule] = useState(() => {
		if (currentProgram?.slug === "parent") {
			return parent?.filter(module => module.slug === crumbs[2])[0]
		} else {
			return facilitator?.filter(module => module.slug === crumbs[2])[0]
		}
	})

	useEffect(async () => {
		setLoading(true)

		setLoading(false)
		moduleNames.map((item, index)=>{
			if(item.slug == props.slug){
				setDropdownBtnTitle(item.title)
			}
		})

	}, []);

	useEffect(() => {
        setCurrentProgram(programs.filter(program => program.slug === crumbs[1])[0])
        if (currentProgram?.slug === "parent") {
            setCurrentModule(parent?.filter(module => module.slug === crumbs[2])[0])
        } else {
            setCurrentModule(facilitator?.filter(module => module.slug === crumbs[2])[0])
        }
    },[facilitator,parent,crumbs])


	return (
		<section className={`${classes.hero} p-inline`} style={{ backgroundImage: `url(${props.bg})` }}>
			{props.type!="caregiver" &&

			<div className={classes.breadcrumb}>
                <p className={classes.breadcrumb__text}>
                    {
                        lang === "ar" ?
                            currentProgram?.title_arabic :
                            currentProgram?.title
                    }
                </p>
                <img src={whiteArrow} className={classes.breadcrumb__arrow} alt=""/>
                <p className={classes.breadcrumb__text} to={`/${currentProgram?.slug}/${currentModule?.slug}`}>
                    {t("Module")} {currentModule?.order} : {lang === "ar" ? currentModule?.title_arabic : currentModule?.title}
                </p>
            </div>
}
			<div className={`${classes.main} col-12 px-0`}>
				<Row className={"mx-0 justify-content-between"}>
					<Col lg={props.isEqual ? 6 : 5} className={classes.row1}>
						{props.sideRight}
					</Col>
					<Col lg={6} className={`${classes.row2} ${lang == "en" ? classes.translate_en : classes.translate_ar} px-0`}>
						{props.sideLeft}
						{props.type=='caregiver' && <img src={images.shape1} className={classes.shapes} alt="shape1" />}
						{props.type=='caregiver' && <img src={images.shape2} className={classes.shapes} alt="shape2" />}
					</Col>
				</Row>
			</div>
			{props.descBottomCol === "google" ? (token === "" ? props.bottomColumn : null) : props.bottomColumn}
		</section>
	);
};
export default CustomHeroSection;
