import React from "react";
import SignIn from "../SignIn";
import classes from "./index.module.scss";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";

export default function ResetPasswordView({
  otp,
  setOtp,
  handleResetPasswordSubmit,
  changeView,
  validationMessage,
  setValidationMessage,
}) {
  const {t}= useTranslation()
  return (
    <SignIn
      title={t("Verify Email")}
      phrase={t("Verify email descripiton")}
      changeView={() => changeView()}
      handleSubmit={handleResetPasswordSubmit}
      secondaryTitle={true}
      showBackBtn={true}
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
    >
      <OtpInput
        value={otp}
        onChange={(val) => {
          setOtp(val);
        }}
        shouldAutoFocus={true}
        numInputs={4}
        containerStyle={classes.otpInputsContainer}
        inputStyle={classes.verifyBox}
        inputType="number"
        renderInput={(props) => (
          <input
            {...props}
            className={`${props.className} ${classes.otpInput} ${
              props.value ? classes.filled : ""
            }`}
          />
        )}
      />
      <Button
        type="submit"
        className={classes.submitBtn}
        disabled={otp.length !== 4}
      >
        {t("reset")}
      </Button>
    </SignIn>
  );
}
