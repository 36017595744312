import { constants } from "../constants";

const initialState = {display:false, variant:"", message:""};
export default function globalAlertReducer(state=initialState,action){
    switch (action.type){
        case constants.SET_GLOBAL_ALERT:
            return {...action.payload};
        default:
            return state
    }
}