import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import classes from "./index.module.scss";
import images from "../../resources/images";
import CustModal from "../Modal";
import { createPortal } from "react-dom";
import { useNavigate } from 'react-router-dom'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
const FeaturedComponent = ({ image, children, btnText, icon, mainData, newTabLink, noModalHeader, navigation, route, type, list }) => {
	const [modalShow, setModalShow] = useState(false);
	const pdfUrl = mainData; // Replace with the path or URL to your PDF file
const navigate = useNavigate();
	return (
		<React.Fragment>
			<Container className={`${classes.wrapper}`} fluid>
				<img src={image} />
				<Row>
					<Col md={8} className={`px-0`}>
						{children}
						<Button className={`f-20 regular`} onClick={navigation?()=>{navigate(route)}:() =>setModalShow(true)}>
							{btnText}
						</Button>
						{list &&
							<ul>
								{list.map((item, index)=>(
									<li>{item}</li>
								))}
							</ul>

						}
					</Col>
					<Col md={4} style={{ alignSelf: "flex-end" }}>
						{icon}
					</Col>
				</Row>
				<CustModal newTabLink={newTabLink} show={modalShow} onHide={() => setModalShow(false)} noModalHeader={noModalHeader}>
					{type=="pdf"?
							<Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
								<Viewer fileUrl={pdfUrl} />
							</Worker>:mainData }
				</CustModal>
			</Container>
		</React.Fragment>
	);
};
export default FeaturedComponent;
