import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import classesEn from "./index.module.scss";
import classesAr from "./indexAr.module.scss";
import { useSelector } from "react-redux";

const CustomAccordionItem = ({ Icon, title, week, currentKey, children }) => {
	// console.log("icon", Icon);
	const lang = useSelector((state) => state.settingsReducer.lang);
	const [classes, setClasses] = useState(classesEn);
	useEffect(() => {
		setClasses(lang == "ar" ? classesAr : classesEn);
	}, [lang]);

	return (
		<Accordion.Item className={classes["accordion-item"]} eventKey={currentKey} id={`week-${currentKey+1}`}>
			<Accordion.Header className={classes.header}>
				{Icon && <Icon />}
				<div className={"d-flex flex-column mx-2 align-items-start"}>
					{week && <span className="f-20 regular">{week}</span>}
					<h3 className={`f-23 bold`}>{title}</h3>
				</div>
			</Accordion.Header>

			<Accordion.Body className={classes.body}>{children}</Accordion.Body>
		</Accordion.Item>
	);
};

export default CustomAccordionItem;
