import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { removeUnauthedToken } from "../../helpers/removeUnauthedTokens";
export const API_getCaregiverData = () => {
  return async (_, getState) => {
    try {
      const token = getState().authReducer.token;

      const response = await axios.get(`${baseUrl}/caregiver/index`, {
        headers: token
          ? {
              Authorization: `Token ${token}`,
            }
          : null,
      });
      // console.log("API_getCaregiverData", response);
      return response;
    } catch (error) {
      // console.error("API_getCaregiverData", error.response);
      if(error?.response?.status === 401){
				removeUnauthedToken()
			}
      return error.response;
    }
  };
};

export const API_postCaregiverInputVals = (item, selected) => {
  return async (_, getState) => {
    try {
      const token = getState().authReducer.token;
      // console.log(getState());
      const response = await axios.post(
        `${baseUrl}/caregiver/store_check_list`,
        {
          items: [
            {
              item,
              selected,
            },
          ],
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log("API_postCaregiverInputVals", response);
      return response;
    } catch (error) {
      if(error?.response?.status === 401){
				removeUnauthedToken()
			}
      // console.error("API_postCaregiverInputVals", error.response);
      return error.response;
    }
  };
};

export const API_postCaregiverChildName = (child_name, id) => {
  return async (_, getState) => {
    try {
      const token = getState().authReducer.token;
      // console.log(getState());
      const response = await axios.post(
        `${baseUrl}/caregiver/store_child_name`,
        {
          child_name,
          checklist: id,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log("API_getCaregiverData", response);
      return response;
    } catch (error) {
      if(error?.response?.status === 401){
				removeUnauthedToken()
			}
      // console.error("API_getCaregiverData", error.response);
      return error.response;
    }
  };
};
