import { translation_ar } from "./langs/ar";
import { translation_en } from "./langs/en";
export const resources = {
  en: {
    translation: translation_en,
  },
  ar: {
    translation: translation_ar,
  },
};
