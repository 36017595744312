import React, { useEffect, useState } from "react";
import CustomHeroSection from "../../../components/CustomHeroSection";
import images from "../../../resources/images";
import classes from "./SectionMain.module.scss";
import Button from "../../../components/Buttons";
import { API_postGoogleAuthData, setName, setToken } from "../../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { authinticate } from "../../../helpers/firebase-config";

const googleClientId = "969246571114-kk2eu638ugn9on6bjdldh9g5bihm054d.apps.googleusercontent.com";
const SectionMain = (props) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.settingsReducer.lang);

  const token = useSelector((state) => state.settingsReducer.token);
  const dispatch = useDispatch();
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authinticate, provider)
      .then(async (response) => {
        const res = await dispatch(API_postGoogleAuthData(response._tokenResponse.idToken));
        if (res.status == 200) {
          localStorage.setItem("token", res.data.token);
          dispatch(setToken(res.data.token));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <CustomHeroSection
      type="caregiver"
      bg={images.greenBg}
      isEqual={true}
      sideRight={
        <React.Fragment>
          <h1 className={`f-32 bold`}>
            {t(
              "The first years in a child’s life are so important. Tracking how little ones play, learn, speak, act and move can help you support their development and act quickly in the event of a delay."
            )}
          </h1>
          <p className="f-23 semi_bold my-4">{t("Caregiver Resources Include")}</p>
          <ul className={classes.items}>
            <li>
              <images.checkmarkgreen />
              <p className="f-18 regular">{t("What most babies do by this age checklist at different ages from 0-24 months.")}</p>
            </li>
            <li>
              <images.checkmarkgreen />
              <p className="f-18 regular">{t("Suggested activities to enjoy with your with children")}</p>
            </li>
          </ul>
        </React.Fragment>
      }
      sideLeft={<img src={images.careGiverImage} />}
      // bottomColumn={
      //   <div className={classes.bg_white}>
      //     <div>
      //       <h3 className={`f-28 bold mb-4`}>{t("Interested in Saving your Child Checklist? Sign in with Google!")}</h3>
      //       <Button onClick={signInWithGoogle} blue={true} hasIcon={true} text={t("Sign in With Google")} route="./"></Button>
      //     </div>

      //     <div className={classes.googleIcon}>{lang == "en" ? <images.googleIcon /> : <images.googleIconAr />}</div>
      //   </div>
      // }
      descBottomCol={"google"}
    />
  );
};

export default SectionMain;
