import React, { useState } from "react";
import SignIn from "../SignIn";
import EmailInput from "../../components/Inputs/EmailInput";
import PasswordInput from "../../components/Inputs/PasswordInput";
import classes from "./index.module.scss";
import {  Button } from "react-bootstrap";
import { regex } from "../../helpers/regex";
import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import ResetPasswordView from "./ResetPasswordView";
import { useTranslation } from "react-i18next";
export default function ForgotPasswordView({
  changeView,
  validationMessage,
  setValidationMessage,
}) {
  //state
  const [emailValue, setEmailValue] = useState("");
  const [newPassword, setNewPassword] = useState({
    password1: "",
    password2: "",
  });
  const [newPasswordIsValid, setNewPasswordIsValid] = useState({
    email: false,
    password1: false,
    password2: false,
  });
  const [errors,setErrors] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const {t}= useTranslation();
  //methods
  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${baseUrl}${endpoints.sendOtp}`, {
        email: emailValue,
      });
      if (res.status == 200) {
        setShowOtp(true);
      }
      return res;
    } catch (err) {
      console.log("err", err);
      return err;
    }
  };
  //those two fuctions for handling change in email and password inputs value

  const handleNewPasswordChange = (e) => {
    setNewPassword((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name == "password1") {
      const isValid = regex.password.test(e.target.value);
      const password2BecameValid = isValid && e.target.value === newPassword.password2;
      
      setNewPasswordIsValid((prev) => ({
        ...prev,
        password1: isValid,
        ...(password2BecameValid ? {password2: true}:{password2:false})
      }));

      !isValid ? setErrors(prev=>({...prev,password1:t("password validation")})):setErrors(prev=>({...prev,password1:""}));
      //validate reenter password on change
      if(password2BecameValid){
        setErrors(prev=>({...prev, password2:""}))
      }
      if(newPassword.password2.length && !password2BecameValid){
        setErrors(prev=>({...prev, password2:t("reEnter password validation")}))
      }
    }
    if (e.target.name === "password2") {
      const isValid =  newPassword.password1 === e.target.value;
      setNewPasswordIsValid((prev) => ({
        ...prev,
        password2: isValid,
      }));
     !isValid ?  setErrors(prev=>({...prev,password2:t("reEnter password validation")})):setErrors(prev=>({...prev,password2:""}))
    }
  };
  const handleEmailChange = (e) => {
    const isValid = regex.email.test(e.target.value);
    setEmailValue((prev) => e.target.value);
    setNewPasswordIsValid((prev) => ({
      ...prev,
      [e.target.name]: isValid,
    }));
    !isValid ?  setErrors(prev=>({...prev,email:t("email validation")})):setErrors(prev=>({...prev,email:""}))

  };

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${baseUrl}${endpoints.resetPassword}`, {
        otp: otpValue,
        ...newPassword,
      });
      if (res.status == 200) {
        changeView("signIn");
      }
      return res;
    } catch (err) {
      console.log("err", err);
      return err;
    }
  };
  //   if(showOtp){
  //     return
  //   }
  return showOtp ? (
    <ResetPasswordView
      otp={otpValue}
      setOtp={setOtpValue}
      handleResetPasswordSubmit={handleResetPasswordSubmit}
      changeView={() => setShowOtp(false)}
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
    />
  ) : (
    <SignIn
      title={t("Forgot Password title")}
      phrase={t("forgot password description")}
      changeView={() => changeView("signIn")}
      handleSubmit={handleForgotPasswordSubmit}
      secondaryTitle={true}
      showBackBtn={true}
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
      isValid={newPasswordIsValid.email &&
        newPasswordIsValid.password1 &&
        newPasswordIsValid.password2}
    >
      <EmailInput
        id={"email"}
        name={"email"}
        value={emailValue}
        onChange={handleEmailChange}
        errorMessage={errors.email}
        label={t("email")}
        placeholder={t("email placeholder")}
      />
      <PasswordInput
        id={"password1"}
        name={"password1"}
        value={newPassword.password1}
        onChange={handleNewPasswordChange}
        onKeyDown={(e) => {
          if (e.code == "Space") e.preventDefault();
        }}
        errorMessage={errors.password1}
        label={t("New Password")}
        placeholder={t("New Password")}
      />
      <PasswordInput
        id={"password2"}
        name={"password2"}
        value={newPassword.password2}
        onChange={handleNewPasswordChange}
        onKeyDown={(e) => {
          if (e.code == "Space") e.preventDefault();
        }}
        errorMessage={errors.password2}
        label={t("Reenter password")}
        placeholder={t("Reenter password")}
      />
      <Button
        type="submit"
        className={classes.submitBtn}
        disabled={
          !newPasswordIsValid.email ||
          !newPasswordIsValid.password1 ||
          !newPasswordIsValid.password2
        }
      >
        {t("send")}
      </Button>
    </SignIn>
  );
}
