import React, { useEffect } from "react";
import {  Modal } from "react-bootstrap";
import images from "../../resources/images";
import classes from "./index.module.scss";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

const CustModal = ({ isReading, noModalHeader, noCloseIcon, modalClassName, headerClassname,...props}) => {
	const lang = useSelector((state) => state.settingsReducer.lang);

	const downloadImage = () => {
		saveAs(props.newTabLink, "image.jpg");
	};

	const goToNewTab = () => {
		window.open(props.newTabLink, "_blank");
	};
	useEffect(()=>{
		if(props.show){
			document.body.style = "overflow:hidden";
			document.documentElement.style = "overflow:unset";
		}else{
			document.body.style = "overflow:hidden"
			document.documentElement.style = "overflow-x:hidden";

		}
	},[props.show])
	return (
		<Modal className={[classes.modal, modalClassName].join(" ")} {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header className={[classes.modal_header, headerClassname].join(" ")}>
				{!noCloseIcon && (
					<images.close onClick={props.onHide} className={`${classes.absolute} ${lang == "en" ? classes.absolute_en : classes.absolute_ar}`} />
				)}
				{!noModalHeader && (
					<Modal.Title className={classes.modal_title} id="contained-modal-title-vcenter ">
						{!props.isParent && (
							<div className={`${classes.circle}`}>
								{props.item?.activity_name === "Talk Together" && <images.f_talk />}
								{props.item?.activity_name === "Move Together" && <images.f_move />}
								{props.item?.activity_name === "Pretend Together" && <images.f_pretend />}
								{props.item?.activity_name === "Explore Together" && <images.f_explore />}
								{props.item?.activity_name === "Create Together" && <images.f_create />}
								{props.item?.activity_name === "Sing Together" && <images.f_sing />}
								{props.item?.activity_name === "Read Together" && <images.f_read />}
								{props.item?.activity_name === "Solve Together" && <images.f_solve />}
							</div>
						)}
						<div className={`mx-3`}>
							<h1 className={`f-23 bold`}>{lang == "en" ? props.item?.activity_name : props.item?.activity_name_arabic}</h1>
							<p className={`f-20 regular`}>{lang == "en" ? props.item?.topic : props.item?.topic_arabic}</p>
						</div>
					</Modal.Title>
				)}
			</Modal.Header>
			{!isReading && (
				<div className={classes.secTitle}>
					{/* <images.zoomin onClick={goToNewTab} />
					<a onClick={downloadImage}>
						<images.download2 />
					</a> */}
				</div>
			)}

			<Modal.Body className={`p-0 ${classes.white_bg}`}>
				<div>{props.children}</div>
			</Modal.Body>
		</Modal>
	);
};
export default CustModal;
