import React from "react";
import { Form } from "react-bootstrap";
import images from "../../../resources/images";
import classes from "../index.module.scss";

export default function EmailInput({ label = "Email", id, name, noIcon, errorMessage,...props }) {
  return (
    <Form.Group
      className={["mb-3", classes.inputWrapper].join(" ")}
      controlId={id}
    >
      <Form.Label>{label}</Form.Label>
      <div className={classes.inputWithIconsWrapper}>
        <Form.Control
          type="email"
          name={name}
          placeholder="Enter your email"
          className={`${!noIcon ? classes.withIcon : ""}`}
          {...props}
        />
        {!noIcon && <images.emailIcon className={classes.icon} />}
      </div>
      {errorMessage && (
        <p className={classes.validationMessage}>{errorMessage}</p>
      )}
    </Form.Group>
  );
}
