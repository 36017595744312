import { constants } from "../constants";

const initialState = { lang: "en" };
export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.CHANGE_LANG:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
}
