import { constants } from "../constants";

const initialState = {time: 0};
export default function activityVideoReducer(state= initialState, action){
    switch(action.type){
        case constants.SET_ACTIVITY_VIDEO:
            return {...state, time: action.payload};
        default:
            return state;
    }
}