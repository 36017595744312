// export const baseUrl = "https://stemey-control.eduplj.com/api";
export const baseUrlDEV="https://plj-stem-ey.discoveryeducationme.com/api"
export const baseUrl ="https://plj-stem-dev.discoveryeducationme.com/api"



export const endpoints = {
  googleAuth: "/auth/google_oauth",
  parent: "/parent/index",
  login:"/auth/login",
  resetPassword:"/auth/password/forgot",
  sendOtp:"/auth/otp/send",
  logout:"/auth/logout"
};
