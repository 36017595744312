import { combineReducers } from "redux";
import authReducer from "./reducers/authReducer";
import settingsReducer from "./reducers/settingsReducer";
import facilitatorReducer from "./reducers/facilitatorReducer";
import signInReducer from "./reducers/signInReducer";
import globalAlertReducer from "./reducers/globalAlertReducer";
import activityVideoReducer from "./reducers/activityVideoReducer";
const rootReducer = combineReducers({ authReducer, settingsReducer, facilitatorReducer, signInReducer,globalAlertReducer,activityVideoReducer });

export default rootReducer;
