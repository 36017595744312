import classes from "./ProgramLevelSubContant1.module.scss";

import React, { Fragment } from "react";
import { t } from "i18next";
const ProgramLevelSubContant1 = (props) => {
	return (
		<Fragment>
			<h3 className={`f-18 bold text-blue`}>{t("Developmental Milestones Checklists and Activities:")}</h3>
			<ul className={classes.pointed_list}>
				<li className={"f-16"}>
					{t(
						"Track your child’s developmental progress by looking for developmental milestones using an interactive, illustrated checklist."
					)}
				</li>
				<li className={"f-16"}>
					{t(
						"Help your baby learn and grow by applying what you can do with them at his developmental milestone. Talk, read, sing, and play together every day."
					)}{" "}
				</li>
			</ul>
		</Fragment>
	);
};
export default ProgramLevelSubContant1;
