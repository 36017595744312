import React, { useRef, useState } from "react";
import ReactPaginate from 'react-paginate';
import images from "../../resources/images";



const Pagination = ({numOfPages, handlePageClick,activePage}) => {
    return(
    <div className="pagination__container">
    <ReactPaginate
        className="pagination"
        pageClassName="pagination__page"
        pageCount={numOfPages}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        forcePage={activePage}
        previousLabel={
            <images.paginationArrow/>

        }
        nextLabel={
            <images.paginationArrow/>

        }
    />
</div>

    )
}

export default Pagination;
