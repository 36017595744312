import classes from "./ProgramLevelContent.module.scss";

import React, { Fragment } from "react";
import Buttons from "../../../components/Buttons";
import { Col, Row } from "react-bootstrap";
import { t } from "i18next";
import { useSelector } from "react-redux";
import images from "../../../resources/images";

const ProgramLevelContant = (props) => {
	const lang = useSelector((state) => state.settingsReducer.lang);

	return (
		<Fragment>
			<Row className={`${classes.header} mx-0`}>
				<Col>


				<div className={`${classes.left}`}>
					<img src={props.img}/>
					<div className={classes.text}>
						<span className={`d-block d-md-none my-3`}>{props.age}</span>
						<h2 className={"f-18 semi_bold"}>{props.title}</h2>
						<p className={`regular f-16`}>{props.desc}</p>
					</div>
					{/* <Buttons route={`../${props.route}`} white={true}>
					<p className={classes.btnText}>{t("Start Now")}</p>
				</Buttons> */}

				</div>
				</Col>



				{/* {props.items && props.items.length > 0 && (
					<Col lg={5}>
						<ul className={`m-0 p-0 ${lang == "en" ? classes.list_en : classes.list_ar}`}>
							{props.items?.map((item, index) => (
								<li className="px-3" key={index}>
									{item}
								</li>
							))}
						</ul>
					</Col>
				)} */}
			</Row>
			<div className={classes.content}>
				<div className={classes.left}>
					<img src={images.avatarImage}/>
				</div>


				<div className={classes.right}>
				{!props.hideSub &&

					<div className={classes.column1}>
						<h2 className={`f-18 meduim`}>{t("Take a deep dive into resources")}</h2>

						{!props.hideStart && (

						<Buttons route={`../${props.route}`} white={true}>
							<p className={classes.btnText}>{t("Let's Start")}</p>
						</Buttons>
						)}
					</div>
}
				<div className={classes.column2}>{props.children}</div>
				</div>
			</div>
		</Fragment>
	);
};
export default ProgramLevelContant;
