import React, { Fragment, useState, useEffect } from "react";
import CustomHeroSection from "../../components/CustomHeroSection";
import VideoCard from "../../components/VideoCard";
import images from "../../resources/images";
import { Row, Col } from "react-bootstrap";
import { API_getFacilitatorData, setWeeklyResources } from "../../store/actions/facilitatorActions";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

import CustomeResourceCard from "../../components/CustomeResourceCard";


import classes from "./index.module.scss";
import FacilitatorAccordion from "../../components/WeekAccordion";
import { useDispatch, useSelector } from "react-redux";
import FeaturedComponent from "../../components/FeaturedComponent";
import {
	BrowserRouter as Router,
	useParams,
	useNavigate

  } from "react-router-dom";
import WeekAccordion from "../../components/WeekAccordion";
import AuthLayout from "../../layouts/AuthLayout";

const Facilitator = () => {
	const {slug}=useParams();
	const [module, setModule]=useState(slug)
	const token = localStorage.getItem("token");
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState("");

	const lang = useSelector((state) => state.settingsReducer.lang);
	const weeklyResources = useSelector((state) => state.facilitatorReducer.weeklyResources);

	const list={
		item1:t(`facilitator-mentor`),
		item2:t(`positive-nursery`),
		item3:t(`positive-nursery`),
		item4:t(`unit-images`)
	}

	useEffect(async () => {
		setLoading(true);
		const res = await dispatch(API_getFacilitatorData(module));
		const weeklyResources = res.data.data.weeks.map((week) => {
			return week.weekly_resources;
		});
		dispatch(setWeeklyResources(weeklyResources));
		if (res.data.data.fictional_story) {
			res.data.data.fictional_story.thumbnail =
				"https://plj-prod-backup.s3.us-east-2.amazonaws.com/stemey/Screen+Shot+2022-03-05+at+3.18.25+AM.png";
		}
		setData(res.data.data);
		setLoading(false);

	}, [slug,token]);



	return (

		<AuthLayout>
			{loading ? (
				<div className={classes.loading}>
					<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
				</div>

			) : (
				<React.Fragment>
					<CustomHeroSection
					title={data.title}
					slug={slug}
						setModule={setModule}
						bg={images.mainPurbleBg}

						sideRight={
							<Fragment>
								<div className={classes.badges}>
									<p className="f-18 meduim">{t(`Module`)} {data.order}</p>
									<span className="badge">
										<images.calendar1 />
										<p className="f-14 semi_bold">{t("4 weeks")}</p>
									</span>
								</div>
								<h1 className={`f-42 bold my-4`}>{lang == "en" ? data.title : data.title_arabic}</h1>
								<p className="f-18 light">{lang == "en" ? data.description : data.description_arabic}</p>
							</Fragment>
						}
						sideLeft={data?.fictional_story !==null && <VideoCard item={data?.fictional_story} type="Video" inPlace={true} />}
						bottomColumn={
							<div className={classes.column2}>
								<Row>
									<Col lg={6}>
										<ul>
											<h3 className={`bold f-23`}>{t("Facilitator Guide")}</h3>
											<li>

												<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />
												<span>{t("8 weekly together practices")}</span>
											</li>
											<li>
												<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />
												<span>{t("Simple instructions sprouts and flowers")}</span>
											</li>
											<li>
												<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />
												<span>{t("Supportive practices for non verbal & newly verbal learners")}</span>
											</li>
											<li>
												<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />
												<span>{t("Suggested Weekly schedule, features 3 STEM blocks with Together Activities")}</span>
											</li>
											<li>
												<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />

												<span>{t("An image Appendix for each module to be used while applying the activities")}</span>
											</li>

											<li>
											<img className="img-fluid" src={images.checkmarkColored} alt="checkmark" />
												<span>{t("Videos to support facilitators apply specific activities & strategies")}</span>
											</li>
										</ul>
									</Col>
									<Col lg={6}>
										<ul>
											<h3 className={`bold f-23`}>{t("Children Guide")}</h3>
											{/* <li>
													<img src={images.checkmarkColored}/>
												<span>{t("Start exploring the theme of each week with your Children by reading out loud  the text of the week in the Children guide")}</span>
											</li> */}
											<li>
												<img src={images.checkmarkColored}/>
												<span>{t("Engaging daily opportunities to develop pre literacy skills through oral language development prompts")}</span>
											</li>
											<li>
													<img src={images.checkmarkColored}/>
												<span>{t("4 nonfictional Texts (1 per week, to be read aloud during “Read Together” activity)")}</span>
											</li>
											<li>
													<img src={images.checkmarkColored}/>
												<span>{t("1 fictional story (1 per theme, to be read during the Circle time or dismissal)")}</span>
											</li>

										</ul>
									</Col>
								</Row>
							</div>
						}
					/>
					<section className="m-block p-inline">
						<h4 className="mb-5 f-32 bold text-center">{t("Module Content")}</h4>
						{data.weeks && <WeekAccordion weeks={data?.weeks} weeklyResources={weeklyResources}  module={data.slug} />}
					</section>
					<div className="p-inline m-bottom ">
						{/* <FeaturedComponent image={images.bluePattren} icon={<images.checklistIcon />} btnText={t("Learn More about Facilitator Guidance")} navigation={false} mainData={lang=="en"?data?.links[0].url:data?.links[0].url_arabic} noModalHeader={true} type={"pdf"} list={list}>
							<h3 className="bold f-28">{t("How Can you Help Children Learn and Grow?")}</h3>
							<span className="d-inline-block">{t("Enjoy doing fun and easy activities every day with children chicklists")}</span>
						</FeaturedComponent> */}
					</div>
				</React.Fragment>
			)}
		</AuthLayout>
	);
};
export default Facilitator;
