import { constants } from "../constants";

const initialState = { weeklyResources: "", facilitatorModules:[], parentModules:[] };
export default function facilitatorReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_WEEKLY_RESOURCES:
      return { ...state, weeklyResources: action.payload };
      case constants.SET_FG_MODULES:
        return{...state, facilitatorModules:action.payload }
      case constants.SET_PFG_MODULES:
        return{...state, parentModules:action.payload }
    default:
      return state;
  }
}
