import React, { useState,useEffect } from "react";
import ForgotPasswordView from "./ForgotPasswordView";
import SignInView from "./SignInView";

export default function LoginViews({ closeModal }) {
  //states
  const [view, setView] = useState("signIn");
  const [validationMessage, setValidationMessage] = useState({
    display: false,
    variant: "",
    message: "",
  });
  // methods
  const changeView = (form) => {
    setView(form);
  };
  useEffect(() => {
    if (validationMessage.display) {
      const timer = setTimeout(() => {
        setValidationMessage({
          display: false,
          variant: "",
          message: "",
        });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [validationMessage.display]);
  // return forgot password based on view state
  if (view === "forgotPassword") {
    return (
      <ForgotPasswordView
        changeView={changeView}
        validationMessage={validationMessage}
        setValidationMessage={setValidationMessage}
      />
    );
  }

  return (
    <SignInView
      changeView={changeView}
      closeModal={closeModal}
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
    />
  );
}
